// extracted by mini-css-extract-plugin
export var alignLeft = "v_rd d_fp d_bG d_dv";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignRight = "v_rf d_fr d_bH d_dx";
export var FAQMainHeader = "v_kt d_kt d_w d_by";
export var FAQComponentHeader = "v_kz d_kz d_bx";
export var FAQComponentParagraph = "v_kv d_kv d_cD";
export var FAQComponentsWrapper = "v_kr d_kr d_cn d_cL d_cR d_c2 d_cX d_dB";
export var FAQComponentsWrapperClean = "v_ks d_ks d_cn d_cL d_cR d_c0 d_dB";
export var accordion__body = "v_kB d_kB d_by";
export var accordion__bodyHidden = "v_kD d_kD d_bw d_dl";
export var FAQDivider = "v_kq d_kq";
export var accordionWrapper = "v_sc d_cg";
export var accordion__item = "v_sd d_cL";
export var accordion_arrow = "v_kH d_kH";
export var accordion_arrow_open = "v_kK d_kK";
export var accordion_arrow_2 = "v_kM d_kM";
export var accordion_arrow_2_open = "v_kP d_kP";
export var Subtitle2Small = "v_sf y_sf y_s3 y_tj";
export var Subtitle2Normal = "v_sg y_sg y_s3 y_tk";
export var Subtitle2Large = "v_sh y_sh y_s3 y_tl";