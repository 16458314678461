// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_sL d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_sM d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_sN d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_sP d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_sQ d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_sR d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_sS d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_sT y_sT";
export var heroExceptionNormal = "x_sV y_sV";
export var heroExceptionLarge = "x_sW y_sW";
export var Title1Small = "x_sX y_sX y_s3 y_s4";
export var Title1Normal = "x_sY y_sY y_s3 y_s5";
export var Title1Large = "x_sZ y_sZ y_s3 y_s6";
export var BodySmall = "x_s0 y_s0 y_s3 y_tm";
export var BodyNormal = "x_s1 y_s1 y_s3 y_tn";
export var BodyLarge = "x_s2 y_s2 y_s3 y_tp";