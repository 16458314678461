// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "B_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "B_fR d_fR d_bz d_bJ";
export var menuDesign6 = "B_t8 d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "B_t9 d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "B_vb d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "B_vc d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "B_vd d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "B_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "B_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "B_vf";
export var navbarItem = "B_nb d_bx";
export var navbarLogoItemWrapper = "B_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "B_vg d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "B_vh d_gd d_by d_Z d_bs";
export var burgerToggle = "B_vj d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "B_vk d_gd d_by d_Z d_bs";
export var burgerInput = "B_vl d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "B_vm d_f3 d_w d_H";
export var burgerLine = "B_vn d_f1";
export var burgerMenuDesign6 = "B_vp d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "B_vq d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "B_vr d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "B_vs d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "B_vt d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "B_vv d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "B_vw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "B_vx d_bC d_bP";
export var compact = "B_vy";
export var navDivided = "B_vz";
export var staticBurger = "B_vB";
export var menu = "B_vC";
export var navbarDividedLogo = "B_vD";
export var nav = "B_vF";